import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataPackages: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PACKAGE(state, payload) {
      state.respDataPackages = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/member/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&member=${item.member}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/member/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/member/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updatePassword({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/member/updatePassword', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateMemberPassword({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/member/updateMemberPassword', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/eCommerce/member/delete?id=${item}`)
          .then(response => {
            // commit('REMOVE', item)
            commit('UPDATE', response.data.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    rollback({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/member/rollback?id=${item}`, {})
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    invite({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/member/invite', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    import({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/application/eCommerce/member/import', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getReservationPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/member/getReservationPackage?id=${item.id}`)
          .then(response => {
            commit('GET_PACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addReservationPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/eCommerce/member/addReservationPackage?id=${item.id}`, item)
          .then(response => {
            commit('GET_PACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateReservationPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/member/updateReservationPackage', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    deleteReservationPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/member/deleteReservationPackage?id=${item.id}`, item)
          .then(response => {
            commit('GET_PACKAGE', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    changeTimeReservationPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/member/changeTimeReservationPackage?id=${item.id}`, item)
          .then(response => {
            commit('GET_PACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
