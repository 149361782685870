<template>
  <b-modal
    :visible="isModalActive"
    :title="
      $t('Time') +
        ` ${
          data ? (data._id ? '[' + subStringObjectId(data._id) + ']' : '') : ''
        }`
    "
    ok-title="Accept"
    no-close-on-backdrop
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <validation-observer ref="formData">
        <b-form-group
          :label="$t('Adjusted remaining time') + ` (${$t('Hour')})`"
          label-for="input-lg-cardBorderRadius"
        >
          <validation-provider
            #default="{ errors }"
            name="Hour"
            vid="hour"
            rules="required"
          >
            <cleave
              id="hour"
              v-model="remainingTimeHour"
              class="form-control"
              :raw="false"
              :options="optionCleave.number"
              :placeholder="$t('Adjusted remaining time')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Adjusted remaining time') + ` (${$t('Minute')})`"
          label-for="input-lg-cardBorderRadius"
        >
          <validation-provider
            #default="{ errors }"
            name="Minute"
            vid="minute"
            rules="required"
          >
            <cleave
              id="minute"
              v-model="remainingTimeMinute"
              class="form-control"
              :raw="false"
              :options="optionCleave.number"
              :placeholder="$t('Adjusted remaining time')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <small>{{ $t("Change to") }} :
        {{
          convertMinutesToReadableTime(
            parseInt(remainingTime.toString().replaceAll(",", ""))
          )
        }}</small>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            @click="submit"
          >
            {{ $t("Accept") }}
            <feather-icon
              icon="CheckIcon"
              size="12"
            />
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BAvatar,
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BAvatar,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      userInfoId: null,
      // remainingTime: 0,
      remainingTimeHour: 0,
      remainingTimeMinute: 0,
      required,
    }
  },
  computed: {
    remainingTime() {
      return (
        parseInt(this.remainingTimeHour.toString().replaceAll(',', '')) * 60
        + parseInt(this.remainingTimeMinute.toString().replaceAll(',', ''))
      )
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues()
        } else {
          const { _id, userInfoId, remainingTime } = this.data
          this.dataId = _id
          this.userInfoId = userInfoId
          // this.remainingTime = remainingTime
          this.remainingTimeHour = Math.floor(remainingTime / 60)
          this.remainingTimeMinute = remainingTime % 60
        }
      }
    },
  },
  methods: {
    subStringObjectId(data) {
      return data.substr(20, data.length)
    },
    convertMinutesToReadableTime(minutes) {
      const MINUTES_IN_YEAR = 525600 // 60 * 24 * 365
      const MINUTES_IN_MONTH = 43800 // 60 * 24 * 30
      const MINUTES_IN_DAY = 1440 // 60 * 24
      const MINUTES_IN_HOUR = 60

      const years = Math.floor(minutes / MINUTES_IN_YEAR)
      minutes %= MINUTES_IN_YEAR

      const months = Math.floor(minutes / MINUTES_IN_MONTH)
      minutes %= MINUTES_IN_MONTH

      const days = Math.floor(minutes / MINUTES_IN_DAY)
      minutes %= MINUTES_IN_DAY

      const hours = Math.floor(minutes / MINUTES_IN_HOUR)
      minutes %= MINUTES_IN_HOUR

      // สร้างข้อความโดยละเว้นค่า 0
      const parts = []
      if (years) parts.push(`${years} ${this.$t('Year')}`)
      if (months) parts.push(`${months} ${this.$t('Month')}`)
      if (days) parts.push(`${days} ${this.$t('Day')}`)
      if (hours) parts.push(`${hours} ${this.$t('Hour')}`)
      if (minutes) parts.push(`${minutes} ${this.$t('Minute')}`)

      // รวมข้อความ
      return parts.join(' ')
    },
    closeModal() {
      this.$emit('update:is-modal-active', false)
    },
    initValues() {
      this.dataId = null
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    submit() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.userInfoId,
            packageId: this.dataId,
            remainingTime: parseInt(this.remainingTime.toString().replaceAll(',', '')),
          }
          store
            .dispatch(
              `${this.storeModuleName}/changeTimeReservationPackage`,
              obj,
            )
            .then(result => {
              this.$emit('update:is-modal-active', false)
              this.$emit('update:show', false)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Success'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: this.$t('Success'),
                },
              })
            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
